import React, { useState, useLayoutEffect } from "react";
import { LocalizedLink } from "gatsby-theme-i18n";
import { useTranslation } from "react-i18next";

function CookiesConsent({
  locale,
  handleContinue = () => {
    return "";
  },
}) {
  const [show, setShow] = useState(false);
  const { t } = useTranslation("cookies");

  useLayoutEffect(() => {
    const hasAccepted = Boolean(localStorage.getItem("hasAccepted")) || false;
    setShow(!hasAccepted);
  }, []);

  const close = () => {
    setShow(false);
    localStorage.setItem("hasAccepted", true);
    handleContinue();
  };

  return (
    <div className={`cookie-wrapper ${show ? "show" : ""}`}>
      <h5 className="mb-0">{t("heading")}</h5>
      <div className="description heading mx-auto mb-1">{t("description")}</div>
      <div className="buttons-wrapper">
        <a onClick={close} className="cookie-link">
          {t("agree")}
        </a>
        <a className="cookie-link disagree">{t("disagree")}</a>
        <LocalizedLink to="/privacy" className="cookie-link disagree">
          {t("readMore")}
        </LocalizedLink>
      </div>
    </div>
  );
}

export default CookiesConsent;
